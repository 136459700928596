import { TranslationsInterface } from '@store/Translations.store'

export const translations: TranslationsInterface = {
  pageTitle: 'Dashboard',
  loginPage: {
    welcomeBack: 'Welcome back!',
    enterEmail: 'Enter your e-mail address',
    logIn: 'Log In',
    redirecting: 'Redirecting',
    pleaseWaitRedirectionToMicrosoft: 'Please wait, we are redirecting you to Microsoft',
  },
  errorPage: {
    somethingWentWrong: 'Something went wrong',
    logOut: 'Log out',
  },
  menu: {
    clientID: 'Client ID',
    selectTenant: 'select tenant',
    transactions: 'Transactions',
    createTransaction: 'Create Transaction',
    settings: 'Settings',
    language: 'Language',
    theme: 'Theme',
    logOut: 'Log Out',
  },
  transactionsPage: {
    pageTitle: 'Transactions',
    unableToFetchTransactions: 'Unable to fetch transactions',
    selectTenant: 'select tenant',
    setActiveTenant: 'Please set an active Tenant',
    prev: 'Prev',
    next: 'Next',
    of: 'of',
    filters: 'Filters',
    clientId: 'Client ID',
    transactionId: 'Transaction ID',
    configurationId: 'Configuration ID',
    applicationNumber: 'Application Number',
    externalReferenceId: 'External Reference ID',
    modularization: 'Modularization',
    status: 'Status',
    tags: 'Tags',
    startDate: 'Start Date',
    endDate: 'End Date',
    hideColumns: 'Hide Columns',
    clearFilters: 'Clear Filters',
    selectPlaceholder: '-- select --',
    noResults: 'no results',
    all: 'all',
    completed: 'completed',
    expired: 'expired',
    pending: 'pending',
    select: 'select',
    notAuthorized: 'Not authorized',
    notAuthorizedMessage: 'You are not authorized to see the transactions list',
    copyToClipboard: 'Copy to clipboard',
  },
  singleTransactionPage: {
    goBack: 'Go Back',
    transaction: 'Transaction',
    unableToFetchTransaction: 'Unable to fetch transaction',
    unableToDeletePiiData: 'Unable to delete Pii data',
    notAuthorized: 'Not authorized',
    notAuthorizedMessage: 'You are not authorized to see the transaction',
    basicInformation: 'Basic information',
    showAuditLog: 'Show audit log',
    deletePiiData: 'Delete Pii data',
    showTransactionHistory: 'Show transaction history',
    events: 'audit log',
    steps: 'history',
    deleteModalTitle: 'Delete PII data?',
    confirm: 'Confirm',
    decline: 'Decline',
  },
  singleTransactionAuditLog: {
    AuditLog: 'Audit Log',
    unableToFetchAuditLog: 'Unable to fetch audit log',
    prev: 'Prev',
    next: 'Next',
    noResults: 'no results',
    type: 'Type',
    publisher: 'Publisher',
    payload: 'Payload',
    timestamp: 'Timestamp',
    loadMore: 'Load more',
  },
  singleTransactionHistory: {
    TransactionHistory: 'Transaction History',
    unableToFetch: 'Unable to fetch transactions history',
    noResults: 'no results',
    step: 'Step',
    timestamp: 'Timestamp',
  },
  createTransactionPage: {
    goBack: 'Go Back',
    createTransaction: 'Create Transaction',
    noWriteRights: `You don't have 'write' permissions for selected tenant`,
    jsonValidated: 'valid',
    errorinLine: 'invalid',
    configurationID: 'configuration ID',
    preferredLanguage: 'Preferred Language',
    themeId: 'Theme ID',
    redirectURL: 'Redirect URL',
    phoneNumber: 'Phone Number',
    email: 'E-mail',
    modularization: 'Modularization',
    PDFdocument: 'PDF Document',
    uploadNewPDF: 'Upload new PDF',
    transactionId: 'Transaction ID',
    proceedToken: 'Proceed Token',
    proceedUrl: 'Proceed URL',
    clientID: 'Client ID',
    payload: 'Payload',
    newTransaction: 'New Transaction',
    editConfiguration: 'Edit Configuration',
    selectMock: 'Select a Mock',
    uploadPDFFile: 'Upload a PDF file',
    clickToSelectFile: 'Click to select file',
    PDFfileInput: 'PDF file input',
    pasteBase64: 'Paste Base64',
    existingPDFmocks: 'Existing PDF Mocks',
    pastePDFbase64: 'Paste PDF Base64',
    PDFbase64Input: 'PDF base64 input',
    clear: 'Clear',
    create: 'Create',
    notAuthorized: 'Not authorized',
    notAuthorizedMessage: 'You are not authorized to create transactions',
  },
  settingsPage: {
    pageTitle: 'Settings',
    tagsSettings: 'Tags settings',
    icons: 'only icons',
    tags: 'only tags',
    both: 'icons + tags',
    notAuthorized: 'Not authorized',
    notAuthorizedMessage: 'You are not authorized to change the settings',
    v3Settings: 'Modularization (test)',
    on: 'on',
    off: 'off (default)',
  },
}
