import { styled } from 'styled-components'
import { StyledUser } from '../TopMenu.styles'

const StyledAvatar = styled.div`
  background-color: #5f36bc;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const UserInfo = () => {
  return (
    <StyledUser>
      <StyledAvatar>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.0005 8.53314C13.9018 8.53314 12.2005 10.2345 12.2005 12.3331C12.2005 14.4318 13.9018 16.1331 16.0005 16.1331C18.0991 16.1331 19.8005 14.4318 19.8005 12.3331C19.8005 11.3253 19.4001 10.3588 18.6875 9.64613C17.9748 8.93349 17.0083 8.53314 16.0005 8.53314ZM10.4671 12.3331C10.4671 9.27716 12.9445 6.7998 16.0005 6.7998C17.468 6.7998 18.8754 7.38278 19.9131 8.42048C20.9508 9.45818 21.5338 10.8656 21.5338 12.3331C21.5338 15.3891 19.0564 17.8665 16.0005 17.8665C12.9445 17.8665 10.4671 15.3891 10.4671 12.3331ZM16.0005 21.8665C13.4662 21.8665 10.7332 22.9681 8.5918 24.9667C8.24188 25.2933 7.69346 25.2744 7.36688 24.9245C7.04029 24.5746 7.0592 24.0261 7.40912 23.6996C9.83917 21.4315 12.9849 20.1331 16.0005 20.1331C19.016 20.1331 22.1618 21.4315 24.5918 23.6996C24.9417 24.0261 24.9606 24.5746 24.634 24.9245C24.3075 25.2744 23.759 25.2933 23.4091 24.9667C21.2677 22.9681 18.5347 21.8665 16.0005 21.8665Z"
            fill="white"
          />
        </svg>
      </StyledAvatar>
      Gustav Johansen
    </StyledUser>
  )
}
