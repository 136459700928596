import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 64px;
  background: ${({ theme }) => theme.colors.secondary};

  grid-area: top-menu;
`

export const StyledMenu = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  padding: 0 24px;

  display: grid;
  grid-template-columns: 60px 1fr 120px 32px auto auto;
  grid-template-rows: 100%;
  gap: 24px;
  grid-template-areas: 'logo ____ search notifications user tenant';

  align-items: center;
  justify-content: center;
`

export const Logo = styled.div`
  grid-area: logo;
`

export const Search = styled.div`
  grid-area: search;
`

export const StyledNotifications = styled.div`
  grid-area: notifications;
  width: 32px;
  height: 32px;
  position: relative;
`

export const StyledUser = styled.div`
  grid-area: user;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Tenant = styled.div`
  grid-area: tenant;
`
