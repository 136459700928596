import { FC } from 'react'
import { SearchInputIcon } from '../TransactionDetailsPage.styles'

export const SearchIcon: FC = () => (
  <SearchInputIcon>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.52065 4.42304C6.7113 4.42304 4.43388 6.67785 4.43388 9.4593C4.43388 12.2408 6.7113 14.4956 9.52065 14.4956C12.33 14.4956 14.6074 12.2408 14.6074 9.4593C14.6074 6.67785 12.33 4.42304 9.52065 4.42304ZM3.33325 9.4593C3.33325 6.07603 6.10344 3.33334 9.52065 3.33334C12.9378 3.33334 15.708 6.07603 15.708 9.4593C15.708 10.9885 15.1421 12.3869 14.2063 13.4602L16.5054 15.7366C16.7203 15.9493 16.7203 16.2943 16.5054 16.5071C16.2905 16.7199 15.9421 16.7199 15.7271 16.5071L13.4161 14.219C12.3525 15.0733 10.997 15.5853 9.52065 15.5853C6.10344 15.5853 3.33325 12.8426 3.33325 9.4593Z"
        fill="currentColor"
      />
    </svg>
  </SearchInputIcon>
)
