import { FC, useContext } from 'react'
import { Popup } from './Popup/Popup'
import { observer } from 'mobx-react'
import { StoreContext } from './App'
import { SelectOption } from '@/constants/SelectOption'
import { toSelectOptions, tenantsSort } from '@/helpers/selectHelpers'
import { useTheme } from 'styled-components'
import { DEV_MODE } from '@/constants/envs'

export const TenantPromptPopup: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { isLogged, shouldLogout } = store.Auth
  const { tenants, selectedTenant } = store.AppState

  const renderTenantSwitcher = () => {
    if (tenants && tenants.length) {
      return (
        // <TenantSelector>
        //   <span>clientId:</span>
        //   <TenantDropdownWrapper>
        //     <SelectInput
        //       options={toSelectOptions(tenants.sort(tenantsSort))}
        //       value={{
        //         value: selectedTenant || '',
        //         label: selectedTenant || 'select tenant',
        //       }}
        //       onChange={(e: SelectOption) => {
        //         void store.AppState.setSelectedTenant(e.value)
        //       }}
        //       {...theme.selectInput}
        //     />
        //   </TenantDropdownWrapper>
        // </TenantSelector>
        <></>
      )
    } else {
      return <></>
    }
  }

  if (!DEV_MODE) {
    return (
      <Popup
        visibility={
          (!localStorage.getItem('selectedTenant')?.length ||
            selectedTenant === 'null' ||
            selectedTenant === 'undefined' ||
            !selectedTenant?.length) &&
          isLogged &&
          !shouldLogout
        }
        {...theme.popup}
        title="Select Tenant"
        width="430px"
        closeIconHidden={true}
      >
        {/* <SelectActiveTenantModalBody>
          Please set an active Tenant:
          {renderTenantSwitcher()}
        </SelectActiveTenantModalBody> */}
      </Popup>
    )
  } else {
    return <></>
  }
})
