import { TopMenu } from '@/components/TopMenu/TopMenu'
import { FC, ReactNode } from 'react'
import { styled } from 'styled-components'

const LayoutContainer = styled.div<{ $isSideMenuOpen?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isSideMenuOpen }) => ($isSideMenuOpen ? '264px' : '82px')} 1fr;
  grid-template-rows: 64px 1fr;
  grid-template-areas:
    'top-menu top-menu'
    'sidebar content';
  flex-direction: column-reverse;
  align-self: flex-start;
  font-family: 'Inter';

  transition: grid-template-columns 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    display: block;
    position: relative;
  }
`

export const PageWrapper = styled.div`
  grid-area: content;
  width: 100%;
  min-height: calc(100vh - 64px);
  padding: 40px 84px;
`

interface DefaultLayoutProps {
  children: ReactNode
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => (
  <LayoutContainer className="default-layout">
    <TopMenu />
    <PageWrapper>{children}</PageWrapper>

    {/* Mobile Menu placement: */}
  </LayoutContainer>
)
