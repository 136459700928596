import { FC, ReactNode } from 'react'
import { toast } from 'react-toastify'
import { css, styled } from 'styled-components'

const getLabel = (action: string, noLabel?: boolean) => {
  if (noLabel) return ''

  switch (action) {
    case 'open':
      return 'Open'
    case 'copy':
      return 'Copy link'
    default:
      return action
  }
}

const getActionIcon = (action: string, children: ReactNode | string) => {
  if (children) return <></>

  switch (action) {
    case 'copy':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2062 4.31367C11.7195 2.80039 14.173 2.80039 15.6863 4.31367C17.1996 5.82695 17.1996 8.28047 15.6863 9.79375L14.2132 11.2669C14.0016 11.4784 13.6587 11.4784 13.4471 11.2669C13.2356 11.0554 13.2356 10.7124 13.4471 10.5009L14.9203 9.02772C16.0105 7.9375 16.0105 6.16992 14.9203 5.0797C13.8301 3.98949 12.0625 3.98949 10.9723 5.0797L9.49912 6.55284C9.28759 6.76438 8.94462 6.76438 8.73309 6.55284C8.52156 6.34131 8.52156 5.99835 8.73309 5.78681L10.2062 4.31367ZM12.4454 7.55458C12.6569 7.76611 12.6569 8.10908 12.4454 8.32061L8.32061 12.4454C8.10908 12.6569 7.76611 12.6569 7.55458 12.4454C7.34304 12.2339 7.34304 11.8909 7.55458 11.6794L11.6794 7.55458C11.8909 7.34304 12.2339 7.34304 12.4454 7.55458ZM6.55284 8.73309C6.76438 8.94462 6.76438 9.28759 6.55284 9.49912L5.0797 10.9723C3.98949 12.0625 3.98949 13.8301 5.0797 14.9203C6.16992 16.0105 7.9375 16.0105 9.02772 14.9203L10.5009 13.4471C10.7124 13.2356 11.0554 13.2356 11.2669 13.4471C11.4784 13.6587 11.4784 14.0016 11.2669 14.2132L9.79375 15.6863C8.28047 17.1996 5.82695 17.1996 4.31367 15.6863C2.80039 14.173 2.80039 11.7195 4.31367 10.2062L5.78681 8.73309C5.99834 8.52156 6.34131 8.52156 6.55284 8.73309Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'open':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3016 3.74992C12.3016 3.45076 12.5441 3.20825 12.8432 3.20825H16.0416C16.4558 3.20825 16.7916 3.54404 16.7916 3.95825L16.7916 7.20075C16.7916 7.4999 16.5491 7.74242 16.2499 7.74242C15.9508 7.74242 15.7083 7.49991 15.7083 7.20075L15.7082 5.05761L10.7996 9.96627C10.5881 10.1778 10.2451 10.1778 10.0336 9.96627C9.82204 9.75473 9.82204 9.41177 10.0336 9.20024L14.9422 4.29159H12.8432C12.5441 4.29159 12.3016 4.04907 12.3016 3.74992ZM3.20825 7.91659C3.20825 5.77648 4.94315 4.04159 7.08325 4.04159H9.58325C9.88241 4.04159 10.1249 4.2841 10.1249 4.58325C10.1249 4.88241 9.88241 5.12492 9.58325 5.12492H7.08325C5.54146 5.12492 4.29159 6.37479 4.29159 7.91659V12.9166C4.29159 14.4584 5.54146 15.7083 7.08325 15.7083H12.0833C13.625 15.7083 14.8749 14.4584 14.8749 12.9166V10.4166C14.8749 10.1174 15.1174 9.87492 15.4166 9.87492C15.7157 9.87492 15.9583 10.1174 15.9583 10.4166V12.9166C15.9583 15.0567 14.2234 16.7916 12.0833 16.7916H7.08325C4.94315 16.7916 3.20825 15.0567 3.20825 12.9166V7.91659Z"
            fill="currentColor"
          />
        </svg>
      )
    default:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3016 3.74992C12.3016 3.45076 12.5441 3.20825 12.8432 3.20825H16.0416C16.4558 3.20825 16.7916 3.54404 16.7916 3.95825L16.7916 7.20075C16.7916 7.4999 16.5491 7.74242 16.2499 7.74242C15.9508 7.74242 15.7083 7.49991 15.7083 7.20075L15.7082 5.05761L10.7996 9.96627C10.5881 10.1778 10.2451 10.1778 10.0336 9.96627C9.82204 9.75473 9.82204 9.41177 10.0336 9.20024L14.9422 4.29159H12.8432C12.5441 4.29159 12.3016 4.04907 12.3016 3.74992ZM3.20825 7.91659C3.20825 5.77648 4.94315 4.04159 7.08325 4.04159H9.58325C9.88241 4.04159 10.1249 4.2841 10.1249 4.58325C10.1249 4.88241 9.88241 5.12492 9.58325 5.12492H7.08325C5.54146 5.12492 4.29159 6.37479 4.29159 7.91659V12.9166C4.29159 14.4584 5.54146 15.7083 7.08325 15.7083H12.0833C13.625 15.7083 14.8749 14.4584 14.8749 12.9166V10.4166C14.8749 10.1174 15.1174 9.87492 15.4166 9.87492C15.7157 9.87492 15.9583 10.1174 15.9583 10.4166V12.9166C15.9583 15.0567 14.2234 16.7916 12.0833 16.7916H7.08325C4.94315 16.7916 3.20825 15.0567 3.20825 12.9166V7.91659Z"
            fill="currentColor"
          />
        </svg>
      )
  }
}

const Container = styled.div<{ $noLabel?: boolean }>`
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 100px;
  height: 32px;

  font-size: 14px;
  font-weight: 400;

  color: ${({ theme }) => theme.transactionDetailsPage.valueButton.color};
  background-color: ${({ theme }) => theme.transactionDetailsPage.valueButton.bgColor};
  border: ${({ theme }) => theme.transactionDetailsPage.valueButton.border};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        filter: brightness(0.9);
      }
    `}

  svg {
    color: ${({ theme }) => theme.transactionDetailsPage.valueButton.iconColor};
  }

  ${({ $noLabel }) =>
    $noLabel &&
    css`
      padding: 0;
      width: 32px;
    `}
`

interface PillProps {
  action?: 'copy' | 'open' | string
  actionData?: string
  onClick?: () => void
  children?: ReactNode | string
  noLabel?: boolean
}

export const ValueButton: FC<PillProps> = ({ action, onClick, actionData, children, noLabel }) => {
  function handleAction(action) {
    if (onClick) {
      onClick()
      return
    } else {
      switch (action) {
        case 'open':
          window.open(actionData, '_blank')
          break
        case 'copy':
          navigator.clipboard.writeText(actionData)
          toast.success('Link copied to clipboard')
          break
      }
    }
  }

  return (
    <Container onClick={() => handleAction(action)} $noLabel={noLabel}>
      {getActionIcon(action, children)}
      {children ? children : getLabel(action, noLabel)}
    </Container>
  )
}
