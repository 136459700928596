import { axiosInstance } from '../axios/AxiosInstance'
import { action, computed, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import { TransactionOld } from '@/pages/TransactionDetailsPage/types'

type Capability = 'read' | 'create' | 'readAuditLog' | 'purgePiiData'

export type TenantsCapabilities = {
  [propName: string]: {
    capabilities: Capability[]
  }
}

export class AppStateStore {
  // tenants: string[] = () => tenantObjToArr(this.tenantsCapabilities)
  tenantsCapabilities: TenantsCapabilities = {}
  selectedTenant = ''
  selectedWriteTenant = ''
  loginPageSubtitle = 'Please login using your account'
  currentTransaction: TransactionOld = {} as TransactionOld

  isUserWithAnyWriteRights = true

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setLoginPageSubtitle(subtitle: string): void {
    this.loginPageSubtitle = subtitle
  }

  @action.bound setTenantsCapabilities(tenants: TenantsCapabilities): void {
    this.tenantsCapabilities = tenants
  }

  @action.bound setSelectedTenant(tenant: string): void {
    this.selectedTenant = tenant
    localStorage.setItem('selectedTenant', tenant)

    // this.rootStore.Filters.fetchTags()
    //   // eslint-disable-next-line no-console
    //   .catch((error) => console.log(error))

    // this.rootStore.Filters.fetchConfigIds()
    //   // eslint-disable-next-line no-console
    //   .catch((error) => console.log(error))
  }

  @action.bound setSelectedWriteTenant(tenant: string): void {
    this.selectedWriteTenant = tenant
  }

  @action.bound setCurrentTransaction(transaction: TransactionOld): void {
    this.currentTransaction = transaction
  }

  @action.bound async fetchTenants() {
    const localSelectedTenant = localStorage.getItem('selectedTenant')

    if (localSelectedTenant?.length && localSelectedTenant !== 'null' && localSelectedTenant !== 'undefined') {
      this.setSelectedTenant(localSelectedTenant)
    }

    const res = await axiosInstance.get<TenantsCapabilities>(`/tenants/capabilities`, {
      withCredentials: true,
    })

    this.setTenantsCapabilities(res.data)

    // Perms check for create transaction
    const createArr: string[] = []

    for (const propName in res.data) {
      if (res.data[propName].capabilities.includes('create')) {
        createArr.push(propName)
      }
    }

    if (!createArr.length) {
      this.setIsUserWithAnyWriteRights(false)
    }

    ///////////////////////////////////////

    if (!this.tenants.find((resTenant) => resTenant === localSelectedTenant)) {
      this.setSelectedTenant(undefined)
    }
  }

  @action.bound setIsUserWithAnyWriteRights(status: boolean) {
    this.isUserWithAnyWriteRights = status
  }

  @computed get tenants(): string[] {
    return Object.keys(this.tenantsCapabilities)
  }

  @computed get tenantsWithWriteRights(): string[] {
    const createArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('create')) {
        createArr.push(propName)
      }
    }

    return createArr
  }

  @computed get tenantsWithAuditLogRights(): string[] {
    const auditLogArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('readAuditLog')) {
        auditLogArr.push(propName)
      }
    }

    return auditLogArr
  }

  @computed get tenantsWithPurgePiiDataRights(): string[] {
    const purgePiiDataArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('purgePiiData')) {
        purgePiiDataArr.push(propName)
      }
    }

    return purgePiiDataArr
  }
}
