import { FC } from 'react'
import { Route, Switch } from 'wouter'
import { DEV_MODE } from '@/constants/envs'
//  Pages
import { NotFoundPage } from '@/pages/NotFoundPage'
import { TransactionDetailsPage } from '@/pages/TransactionDetailsPage/TransactionDetailsPage'
// Layouts
import { UnauthorizedLayout } from '@/layouts/UnauthorizedLayout'
import { DefaultLayout } from '@/layouts/DefaultLayout'

export const ContentRouter: FC = () => (
  <Switch>
    {/* Remaking pages in progress */}
    {/* <Route path="/transactions">
      <DefaultLayout>
        <TransactionsPage />
      </DefaultLayout>
    </Route> */}
    <Route path="/transactions/:transactionId">
      <DefaultLayout>
        <TransactionDetailsPage />
      </DefaultLayout>
    </Route>

    {/* <Route path="/create-transaction">
      <DefaultLayout>
        <CreateTransactionPage />
      </DefaultLayout>
    </Route> */}
    {/* <Route path="/settings">
      <DefaultLayout>
        <SettingsPage />
      </DefaultLayout>
    </Route> */}

    {/* Development route */}
    {DEV_MODE && (
      <Route path="/dev">
        <DefaultLayout>
          <TransactionDetailsPage />
        </DefaultLayout>
      </Route>
    )}

    {/* Remaking pages in progress */}
    {/* <Route path="/">
      <UnauthorizedLayout>
        <LoginPage />
      </UnauthorizedLayout>
    </Route>
    <Route path="/login">
      <UnauthorizedLayout>
        <LoginPage />
      </UnauthorizedLayout>
    </Route>
    <Route path="/error">
      <UnauthorizedLayout>
        <ErrorPage />
      </UnauthorizedLayout>
    </Route>
    <Route path="/unauthorized">
      <UnauthorizedLayout>
        <ErrorPage />
      </UnauthorizedLayout>
    </Route>
    <Route path="/unauthorized/:message">
      <UnauthorizedLayout>
        <ErrorPage />
      </UnauthorizedLayout>
    </Route> */}

    {/* Default route in a switch */}
    <Route>
      <UnauthorizedLayout>
        <NotFoundPage />
      </UnauthorizedLayout>
    </Route>
  </Switch>
)
