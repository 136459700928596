import { AppStateStore } from './AppState.store'
import { AuthStore } from './Auth.store'
import { SettingsStore } from './Settings.store'
import { TranslationsStore } from './Translations.store'
import { TransactionDetailsStore } from './TransactionDetails.store'

export class RootStore {
  AppState: AppStateStore
  Auth: AuthStore
  Settings: SettingsStore
  TranslationsState: TranslationsStore
  TransactionDetailsState: TransactionDetailsStore

  constructor() {
    this.AppState = new AppStateStore(this)
    this.Auth = new AuthStore(this)
    this.Settings = new SettingsStore(this)
    this.TranslationsState = new TranslationsStore(this)
    this.TransactionDetailsState = new TransactionDetailsStore(this)
  }
}

export const rootStore = new RootStore()
