import { action, computed, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import { ContainerStates, Provider, TransactionRaw } from '@/pages/TransactionDetailsPage/types'
import { dataMock } from '@/pages/TransactionDetailsPage/mock'

export class TransactionDetailsStore {
  rootStore: RootStore
  transactionRaw: TransactionRaw = dataMock as TransactionRaw

  get providers(): Provider[] {
    return Object.keys(this.transactionRaw.providers).map((key) => {
      return {
        providerName: key,
        ...this.transactionRaw.providers[key],
      }
    })
  }

  containerStates: ContainerStates = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      providers: computed,
    })
    this.rootStore = rootStore
  }

  @action.bound setTransactionDetails = (transactionRaw: TransactionRaw): void => {
    this.transactionRaw = transactionRaw
  }

  @action.bound setContainerStates = (containerStates: ContainerStates): void => {
    this.containerStates = containerStates
  }

  @action.bound setContainerCollapsedState = (containerId: string, isCollapsed: boolean): void => {
    this.setAllContainersCollapsed()
    console.log('setContainerCollapsedState', containerId, isCollapsed)

    if (!this.containerStates[containerId]) {
      this.containerStates[containerId] = {
        providerName: containerId,
        isCollapsed: isCollapsed,
        isHighlighted: false,
      }
    } else {
      this.containerStates[containerId].isCollapsed = isCollapsed
    }
  }

  @action.bound setAllContainersCollapsed = (): void => {
    Object.keys(this.containerStates).forEach((key) => {
      this.containerStates[key].isCollapsed = true
    })
  }

  @action.bound setContainerHighlighted = async (containerId: string) => {
    this.containerStates[containerId].isHighlighted = true

    setTimeout(() => {
      this.containerStates[containerId].isHighlighted = false
    }, 2000)
  }
}
