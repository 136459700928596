import { createContext, FC, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { observer } from 'mobx-react'
import { Helmet } from './Helmet'
import { ContentRouter } from './ContentRouter'
import { rootStore } from '@store/Root.store'
import { GlobalStyles } from '@styles/App.styles'
import { themes } from '@/themes/themes'
import { AxiosErrorHandler } from '@/axios/AxiosErrorHandler'
import { Toasts } from './Toasts/Toasts'
import { useDarkMode } from '@hooks/useDarkmode'
import { TenantPromptPopup } from './TenantPromptPopup'
import { DEV_MODE } from '@/constants/envs'
import { AuthHandler } from './AuthHandler'

export const StoreContext = createContext(rootStore)

export const App: FC = observer(() => {
  const { isDarkMode } = useDarkMode()
  const theme = isDarkMode ? themes.darkTheme : themes.lightTheme
  const store = useContext(StoreContext)
  const { currentUserId, isLogged, shouldLogout } = store.Auth

  useEffect(() => {
    if (isLogged && !shouldLogout && !DEV_MODE) {
      void store.AppState.fetchTenants()
      store.Auth.initKeepSessionAliveInterval()
      return
    }

    return () => {
      store.Auth.disposeKeepSessionAliveInterval()
    }
  }, [isLogged, shouldLogout])

  useEffect(() => {
    if (!DEV_MODE) {
      void store.Auth.checkAuth()
    }
  }, [currentUserId, isLogged, shouldLogout])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet title="Signicat Onboarding Dashboard" description="Onboarding Dashboard" themeColor="#320822" />

      <ThemeProvider theme={theme}>
        <AxiosErrorHandler>
          <AuthHandler>
            <GlobalStyles />
            <ContentRouter />
            <Toasts />
            <TenantPromptPopup />
          </AuthHandler>
        </AxiosErrorHandler>
      </ThemeProvider>
    </StoreContext.Provider>
  )
})
