import { css, styled } from 'styled-components'

export const CollapsibleContainer = styled.div<{ $isCollapsed: boolean; $isHighlighted?: boolean }>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.backgroundColor};
  outline: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.border};
  box-shadow: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.boxShadow};
  padding: 20px;
  border-radius: 8px;

  overflow: hidden;
  transition:
    max-height 0.1s ease-in-out,
    outline 0.1s ease-in-out,
    filter 0.1s ease-in-out;
  max-height: 1000px;

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      grid-template-rows: 40px;
      max-height: 80px;
    `}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      outline: 1px solid ${({ theme }) => theme.transactionDetailsPage.dataGridItem.iconColor};
      filter: drop-shadow(0px 0px 2px ${({ theme }) => theme.transactionDetailsPage.dataGridItem.iconColor});
    `}
`

export const TopContent = styled.div`
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: 40px auto 40px;
  gap: 0 10px;
`

export const BottomContent = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
`

export const TypeIcon = styled.div<{ $size?: 'small' | 'large' }>`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.transactionDetailsPage.dataGridItem.treeLineColor}`};
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.iconColor};

  ${({ $size }) =>
    $size === 'small' &&
    `
    width: 20px;
    height: 20px;
    border: none;
  `}
`

export const CollapseIcon = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  aspect-ratio: 1;
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.expandIconColor};
  cursor: pointer;

  /* transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? '0deg' : '180deg')}); */
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'scale(1, 1)' : 'scale(-1, -1)')};
  transition: transform 0.3s;

  svg {
    width: 24px;
    height: 24px;
  }
`

export const ContainerTitle = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.titleColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  align-self: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  user-select: none;

  display: flex;
  align-items: center;
  gap: 10px;
`

export const RootLineContainer = styled.div<{ $isCollapsed?: boolean }>`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;

  transition: height 0.1s ease-in-out;

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      height: 0;
    `}
`

export const RootLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.treeLineColor};
  justify-self: center;
  display: flex;
`

export const TreeViewContainer = styled.div`
  padding-top: 20px;
  grid-column: 2/ 4;
  grid-row: 3;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TreeRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyValueColor};
  font-size: 14px;

  span {
    line-height: 32px;
    display: inline-block;

    color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
  }
`

export const SubTreeObject = styled.div.attrs({ className: 'sub-tree-object' })`
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyValueColor};
  font-size: 14px;

  span {
    line-height: 32px;
    display: inline-block;

    color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
  }
`

export const SubTreeRow = styled.div.attrs({ className: 'sub-tree-row' })<{ $isFirstLoop?: boolean }>`
  margin-left: ${({ $isFirstLoop }) => ($isFirstLoop ? '0' : '20px')};
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyValueColor};
  font-size: 14px;

  span {
    line-height: 32px;
    display: inline-block;

    color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
  }
`

export const ObjectSubtree = styled.div.attrs({ className: 'object-subtree' })`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* gap: 10px; */

  span {
    line-height: 32px;
    display: inline-block;
    font-size: 14px;
    color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
  }
`

export const String = styled.div`
  display: flex;
  min-height: 32px;
  align-items: center;
`

export const Boolean = styled.div``

export const Number = styled.div``

export const URL = styled.div`
  margin-bottom: 10px;
  width: 100%;
  min-height: 32px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
`
