import { StoreContext } from '@/components/App'
import { SelectOption } from '@/constants/SelectOption'
import { tenantsSort, toSelectOptions } from '@/helpers/selectHelpers'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { useLocation } from 'wouter'
import { Tenant } from '../TopMenu.styles'
import { Animatable } from '@/styles/generic.styles'
import Select, { components, IndicatorsContainerProps } from 'react-select'

const TenantSelector = styled.div<{
  $hideOnMobile?: boolean
}>`
  height: 28px;
  padding: 0 4px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  span {
    width: 76px;
    height: 20px;
    display: block;
    overflow: hidden;
    word-break: keep-all;
    color: #dccdff;
    font-family: Inter;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  border-radius: var(--Radius-s, 4px);
  border: 1px solid var(--Stroke-accent, #794cde);
`

const TenantDropdownWrapper = styled.div`
  height: 20px;
  width: 100%;

  & > div {
    .tenant-select {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__control {
        min-height: 20px;
        height: 20px;
        color: #fff;
        background-color: transparent;

        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 4px 0px 0px;
        box-shadow: none;
        border: 0;

        :hover {
          box-shadow: none;
        }

        &--is-focused {
          box-shadow: none;
        }

        &--is-disabled {
          box-shadow: none;
        }

        ${Animatable}
      }

      &__value-container {
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;

        color: #fff;

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
      }

      &__menu-list {
      }
    }
  }
`

const IndicatorsContainer = (props: IndicatorsContainerProps) => {
  return (
    <div>
      {/* <components.IndicatorsContainer {...props} /> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M5.83301 7.9165L9.99967 12.0832L14.1663 7.9165H5.83301Z" fill="white" />
      </svg>
    </div>
  )
}

export const TenantSwitcher: FC = observer(() => {
  const theme = useTheme()
  const [location, setLocation] = useLocation()
  const store = useContext(StoreContext)
  const { tenantsWithWriteRights, selectedTenant, tenants } = store.AppState
  const { menu: trans } = store.TranslationsState.translations

  const tenantsList = toSelectOptions(tenants.sort(tenantsSort))
  const selectedTenantIndex = tenantsList.findIndex((item) => item.value === selectedTenant)

  return (
    <Tenant>
      <TenantSelector>
        <span>{trans.clientID}:</span>
        <TenantDropdownWrapper>
          <Select
            options={tenantsList}
            value={tenantsList[selectedTenantIndex]}
            onChange={(e: SelectOption) => {
              void store.AppState.setSelectedTenant(e.value)
              if (location !== '/transactions') {
                setLocation('/transactions')
              }
            }}
            placeholder={trans.selectTenant}
            classNamePrefix={'tenant-select'}
            components={{ IndicatorsContainer }}
          />
        </TenantDropdownWrapper>
      </TenantSelector>
    </Tenant>
  )
})
