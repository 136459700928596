import { styled } from 'styled-components'
import { StyledNotifications } from '../TopMenu.styles'

const Counter = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary4};
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
`
export const Notifications = () => {
  return (
    <StyledNotifications>
      <Counter>64</Counter>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.9639 5.74896C19.7256 5.74896 18.7284 6.74727 18.7284 7.97059C18.7284 9.19391 19.7256 10.1922 20.9639 10.1922C22.2022 10.1922 23.1994 9.19391 23.1994 7.97059C23.1994 6.74727 22.2022 5.74896 20.9639 5.74896ZM16.9951 7.97059C16.9951 5.78267 18.7756 4.01562 20.9639 4.01562C23.1521 4.01562 24.9327 5.78267 24.9327 7.97059C24.9327 10.1585 23.1521 11.9256 20.9639 11.9256C18.7756 11.9256 16.9951 10.1585 16.9951 7.97059ZM14.9559 4.91158C15.116 5.36267 14.8801 5.85811 14.429 6.01818C12.5758 6.67577 11.239 8.46816 11.239 10.5838C11.239 10.7929 11.2475 11.0036 11.2563 11.2235C11.294 12.1592 11.3385 13.2609 10.7631 15.1165C10.608 15.6169 10.2502 16.2899 9.8438 16.9774C9.42385 17.6879 8.90564 18.4896 8.37961 19.2729C7.61806 20.4068 8.43602 21.9278 9.74993 21.9278H22.2506C23.5645 21.9278 24.3825 20.4068 23.6209 19.2729C22.776 18.0147 21.9299 16.6781 21.4931 15.7523C21.2889 15.3194 21.4743 14.8029 21.9072 14.5987C22.3401 14.3945 22.8566 14.5799 23.0608 15.0128C23.4347 15.8054 24.2107 17.0421 25.0599 18.3065C26.5621 20.5434 25.0097 23.6611 22.2506 23.6611H20.5847C20.5306 24.9832 20.0445 26.0653 19.2193 26.824C18.3546 27.6192 17.1994 27.9847 16.0004 27.9847C14.8015 27.9847 13.6463 27.6192 12.7816 26.824C11.9564 26.0653 11.4703 24.9832 11.4162 23.6611H9.74993C6.99082 23.6611 5.4384 20.5434 6.94066 18.3065C7.45787 17.5363 7.95563 16.7654 8.35165 16.0954C8.76122 15.4025 9.01942 14.8873 9.10752 14.6032C9.5897 13.0481 9.55865 12.237 9.52422 11.3376C9.51504 11.0978 9.50563 10.8518 9.50563 10.5838C9.50563 7.72319 11.3152 5.28389 13.8493 4.38464C14.3004 4.22457 14.7959 4.46049 14.9559 4.91158ZM13.1515 23.6611C13.2022 24.5451 13.5233 25.1513 13.9548 25.5482C14.4374 25.9919 15.1434 26.2514 16.0004 26.2514C16.8575 26.2514 17.5635 25.9919 18.0461 25.5482C18.4776 25.1513 18.7987 24.5451 18.8494 23.6611H13.1515Z"
          fill="white"
        />
      </svg>
    </StyledNotifications>
  )
}
