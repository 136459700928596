export const dataMock = {
  status: 'completed',
  creationUnixTimeSeconds: 1727943135,
  configurationId: 'ikano.de',
  reportUrl: {
    apiUrl:
      'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/report.pdf',
    webUrl:
      'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=report.pdf&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
  },
  transactionId: '8a5bb14e-43d0-4e43-bb08-5e5ea745384e',
  providers: {
    eidv: {
      lastName: 'MUSTERMANN',
      placeOfBirth: 'BERLIN',
      document: {
        issuingAuthority: 'STADT KOLN',
        dateOfExpiry: '2029-11-30',
        nationality: 'DEU',
        documentType: 'national_identity_card',
        issuingCountry: 'DEU',
        documentNumber: 'T015ZG7MT',
        authority: 'DEU',
        dateOfIssue: '2019-12-01',
      },
      dateOfBirth: '1964-08-12',
      title: null,
      result: 'accepted',
      firstName: 'ERIKA',
      faceUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/face.jpg',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=face.jpg&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      documentBackSideUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/backSide.jpg',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=backSide.jpg&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      handWrittenSignatureOnDocumentUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/handWrittenSignatureOnDocument.jpg',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=handWrittenSignatureOnDocument.jpg&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      nationalId: null,
      videoUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/video.mp4',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=video.mp4&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      contact: {
        phone: '+34645745027',
      },
      faceOnDocumentUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/faceOnDocument.jpg',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=faceOnDocument.jpg&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      documentFrontSideUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/frontSide.jpg',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=frontSide.jpg&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      status: 'pending',
    },
    signature: {
      result: 'failure',
      signedDocumentUrl: {
        apiUrl:
          'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/eidv/signedDocument.pdf',
        webUrl:
          'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=signedDocument.pdf&provider=eidv&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
      },
      type: 'qes',
      status: 'completed',
    },
    addressVerification: {
      result: 'success',
      name: '',
      documentExtractionMetadata: {
        firstname: null,
        providerOutput: 'Address 51147 KOLN HEIDESTRASSE 17 Orders oder Kinstlername Nom the religion',
        address: {
          city: {
            checkType: 'findAndMatch',
            result: 'success',
            score: 80,
            extractedFromDocument: 'KOLN',
            numberOfCharactersMismatched: 1,
            expected: 'Köln',
          },
          street: {
            checkType: 'findAndMatch',
            result: 'success',
            score: 100,
            extractedFromDocument: 'HEIDESTR. 17',
            numberOfCharactersMismatched: 0,
            expected: 'Heidestrasse 17',
          },
          postalCode: {
            checkType: 'findAndMatch',
            result: 'success',
            score: 100,
            extractedFromDocument: '51147',
            numberOfCharactersMismatched: 0,
            expected: '51147',
          },
        },
        unmatchedSegments: ['ADDRESS', 'ORDERS', 'ODER', 'KINSTLERNAME', 'NOM', 'THE', 'RELIGION'],
        document: {
          issueDate: null,
          documentType: 'identity_card',
        },
        accuracy: '93.33',
        evidenceUrls: [
          {
            apiUrl:
              'https://onboarding-artifacts.prod-eu.identity.signicat.global/dev.pink/8a5bb14e-43d0-4e43-bb08-5e5ea745384e/addressVerification/IdCard.jpg',
            webUrl:
              'https://web-api.prod-eu.identity.signicat.global/onboarding-dashboard/artifacts?transactionId=8a5bb14e-43d0-4e43-bb08-5e5ea745384e&artifactName=IdCard.jpg&provider=addressVerification&tenantId=dev.pink&isOnboardingArtifactsStorage=true',
          },
        ],
        lastname: null,
      },
      address: 'HEIDESTR. 17 51147 KOLN',
      type: 'documentExtraction',
      status: 'completed',
    },
    micropayment: {
      result: 'success',
      senderBankName: 'Testbank',
      senderHolder: 'ERIKA MSUTERMANN',
      senderIban: 'DE62888888880012345678',
      senderBic: 'TESTDE88XXX',
      status: 'completed',
      senderCountryId: 'DE',
    },
    corroboration: {
      result: 'success',
      eidv: {
        result: 'success',
        score: 100,
        firstName: [
          {
            checkType: 'findAnyAndMatch',
            result: 'success',
            actual: 'ERIKA',
            score: 100,
            comparison: 'application.firstName',
            numberOfCharactersMismatched: 0,
            expected: 'ERIKA',
          },
        ],
        lastName: [
          {
            checkType: 'match',
            result: 'success',
            actual: 'MUSTERMANN',
            score: 100,
            comparison: 'application.lastName',
            numberOfCharactersMismatched: 0,
            expected: 'MUSTERMANN',
          },
        ],
        'document.dateOfIssue': [
          {
            checkType: 'notEmpty',
            result: 'success',
            actual: '2019-12-01',
            comparison: 'eidv.document.dateOfIssue',
            expected: '2019-12-01',
          },
        ],
        placeOfBirth: [
          {
            checkType: 'notEmpty',
            result: 'success',
            actual: 'BERLIN',
            comparison: 'eidv.placeOfBirth',
            expected: 'BERLIN',
          },
        ],
        'document.issuingAuthority': [
          {
            checkType: 'notEmpty',
            result: 'success',
            actual: 'STADT KOLN',
            comparison: 'eidv.document.issuingAuthority',
            expected: 'STADT KOLN',
          },
        ],
        'document.nationality': [
          {
            checkType: 'anyEquality',
            result: 'success',
            actual: 'DEU',
            comparison: 'application.nationalities',
            expected: ['POL', 'DEU', 'ESP', 'GBR'],
          },
        ],
        dateOfBirth: [
          {
            checkType: 'equality',
            result: 'success',
            actual: '1964-08-12',
            comparison: 'application.dateOfBirth',
            expected: '1964-08-12',
          },
        ],
      },
      addressVerification: {
        result: 'success',
        score: 93.33333333333333,
        address: [
          {
            checkType: 'findAndMatch',
            result: 'success',
            actual: 'KOLN',
            score: 80,
            numberOfCharactersMismatched: 1,
            expected: 'Köln',
          },
          {
            checkType: 'findAndMatch',
            result: 'success',
            actual: '51147',
            score: 100,
            numberOfCharactersMismatched: 0,
            expected: '51147',
          },
          {
            checkType: 'findAndMatch',
            result: 'success',
            actual: 'HEIDESTR. 17',
            score: 100,
            numberOfCharactersMismatched: 0,
            expected: 'Heidestrasse 17',
          },
        ],
      },
      micropayment: {
        result: 'success',
        score: 90,
        senderHolder: [
          {
            checkType: 'findAnyAndMatch',
            result: 'success',
            actual: 'ERIKA',
            score: 100,
            comparison: 'eidv.firstName',
            numberOfCharactersMismatched: 0,
            expected: 'ERIKA',
          },
          {
            checkType: 'findAndMatch',
            result: 'success',
            actual: 'MSUTERMANN',
            score: 80,
            comparison: 'eidv.lastName',
            numberOfCharactersMismatched: 2,
            expected: 'MUSTERMANN',
          },
        ],
      },
      customClientFields: {
        ikano: {
          nonFullMatchFields: [
            'addressVerification.documentExtractionMetadata.address.city.extractedFromDocument',
            'addressVerification.documentExtractionMetadata.address.street.extractedFromDocument',
          ],
          isFullMatch: false,
          germanCharactersPresent: false,
        },
      },
      status: 'completed',
    },
  },
  completionUnixTimeSeconds: 1727943455,
  tags: ['proceeded', 'addressVerificationSuccess', 'micropaymentSuccess', 'corroborationSuccess', 'success'],
  redirectUrl: 'https://signicat.com',
  user: {
    firstName: 'Erika',
    lastName: 'Mustermann',
    phoneNumber: '+34645745027',
    address: {
      city: 'Köln',
      street: 'Heidestrasse 17',
      postalCode: '51147',
    },
    bankInformation: {
      accountHolder: 'Erika Mustermann',
      iban: 'DE62888888880012345678',
    },
    dateOfBirth: '1964-08-12',
    nationalities: ['POL', 'DEU', 'ESP', 'GBR'],
    email: 'test@test.com',
  },
}
