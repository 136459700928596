import { styled } from 'styled-components'
import { Link } from 'wouter'

export const Content = styled.div`
  max-width: 1440px;
  /* padding: 40px 84px; */
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin: 0 auto;
`

export const TopContent = styled.div`
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 40px auto;
  gap: 8px 12px;

  align-items: center;
  justify-items: start;
`

export const Title = styled.h1`
  margin: 0;
  grid-column: 2 / 4;
  grid-row: 2;
  color: ${({ theme }) => theme.transactionDetailsPage.title.secondaryColor};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  span {
    color: ${({ theme }) => theme.transactionDetailsPage.title.primaryColor};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
`

export const BackButton = styled(Link)`
  grid-column: 1 / 4;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  color: #25292c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  text-decoration: none;

  &:hover {
    filter: brightness(1.2);
  }
`

export const TitleIconContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchInputContainer = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
`

export const SearchInputIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.transactionDetailsPage.searchBar.iconColor};
`

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 38px;
  border: ${({ theme }) => theme.transactionDetailsPage.searchBar.border};
  color: ${({ theme }) => theme.transactionDetailsPage.searchBar.color};
  border-radius: 4px;
  outline: none;
`

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
