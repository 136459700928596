import { FC } from 'react'
import { styled } from 'styled-components'

const getTagIcon = (tag: string) => {
  switch (tag) {
    case 'success':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2232 6.29067C16.4309 6.50601 16.4247 6.84892 16.2093 7.05658L8.43183 14.5566C8.22201 14.7589 7.88969 14.7589 7.67986 14.5566L3.79069 10.8066C3.57534 10.599 3.56909 10.256 3.77674 10.0407C3.98438 9.82534 4.32729 9.81909 4.54264 10.0267L8.05581 13.4142L15.4573 6.27676C15.6727 6.0691 16.0156 6.07533 16.2232 6.29067Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'proceeded':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8686 5.20331C12.6817 5.43691 12.7196 5.77778 12.9532 5.96466L16.3065 8.64727C16.4524 8.76403 16.4524 8.98601 16.3065 9.10277L12.9532 11.7854C12.7196 11.9723 12.6817 12.3131 12.8686 12.5467C13.0555 12.7803 13.3964 12.8182 13.63 12.6313L16.9832 9.94872C17.6713 9.39827 17.6713 8.35177 16.9832 7.80133L13.63 5.11872C13.3964 4.93184 13.0555 4.96971 12.8686 5.20331Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.53528 5.20331C9.3484 5.43691 9.38627 5.77778 9.61988 5.96466L12.9731 8.64727C13.1191 8.76403 13.1191 8.98601 12.9731 9.10277L9.61987 11.7854C9.38627 11.9723 9.3484 12.3131 9.53528 12.5467C9.72216 12.7803 10.063 12.8182 10.2966 12.6313L13.6499 9.94871C14.3379 9.39827 14.3379 8.35177 13.6499 7.80133L10.2966 5.11872C10.063 4.93184 9.72216 4.96971 9.53528 5.20331Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.37501 9.41669C4.83321 9.41669 3.58334 10.6666 3.58334 12.2083L3.58333 15.125C3.58333 15.4242 3.34082 15.6667 3.04167 15.6667C2.74251 15.6667 2.5 15.4242 2.5 15.125L2.5 12.2083C2.50001 10.0682 4.2349 8.33335 6.37501 8.33335L13.0418 8.33335C13.3409 8.33335 13.5834 8.57586 13.5834 8.87502C13.5834 9.17417 13.3409 9.41669 13.0418 9.41669L6.37501 9.41669Z"
            fill="currentColor"
          />
        </svg>
      )
    default:
      return <></>
  }
}

const Container = styled.div`
  display: flex;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 100px;
  height: 24px;

  font-size: 14px;
  font-weight: 400;

  color: ${({ theme }) => theme.transactionDetailsPage.tag.color};
  background-color: ${({ theme }) => theme.transactionDetailsPage.tag.bgColor};
  border: ${({ theme }) => theme.transactionDetailsPage.tag.border};
`

interface PillProps {
  tag?: string
}

export const Tag: FC<PillProps> = ({ tag }) => {
  const capitalizedTag = tag?.charAt(0).toUpperCase() + tag?.slice(1)

  return (
    <Container>
      {capitalizedTag}

      {getTagIcon(tag)}
    </Container>
  )
}
