import { Colors, Json, Theme, TransactionDetailsPage } from './theme'
import { commonTheme } from './common.theme'

const colors: Colors = {
  primary: '#F2A3B3',
  primary2: '#CC8593',
  secondary: '#F2A3B3',
  secondary2: '#FFFFFF',
  secondary3: '#433D49',
  secondary4: '#1C1A1F',
  secondary5: '#2A0062',
  secondary6: '#7F7B87',
  secondary7: '#CC8593',
  secondary8: '#491296',
  secondary9: '#F2A3B3',
  tertiary: '#FFFFFF',
  tertiary2: '#1C1A1F',
  tertiary3: '#252229',
  quaternary: '#252229',
  quaternary2: '#2A0062',
  quaternary3: '#FFFFFF',
  quaternary4: '#7F7B87',
  quaternary5: '#F2A3B3',
  quinary: '#413F45',
  senary: '#7F7B87',
  septenary: '#FFFFFF',
  septenary2: '#98959F',
  septenary3: '#F2A3B3',
  scrollTrackColor: '#7F7B87',
  scrollHandleColor: '#F2A3B3',
}

const json: Json = {
  textColor: '#F2EDFE',
  textSecondaryColor: '#c6c4c4',
  headlineColor: '#F2EDFE',
  backgroundColor: '#252229',
  lineHeight: '22px',
  linePadding: '0px 3px 0px 4px',
  borderDefault: 'rgba(255, 255, 255, 0.23)',
  borderFocused: '#05aeca',
  borderInvalid: '#9d190f',
  selectionMatch: '#ae38a782',
  activeLineGutter: '#303438',
  activeLine: '#303438',
  activeSelection: '#1872b9',
  dividerColor: '#EEE',
  spacing: '4px',
  contentPadding: `calc(4px / 2)`,
  borderRadius: '8px',
}

const transactionDetailsPage: TransactionDetailsPage = {
  backButton: {
    color: '#FFFFFF',
  },
  title: {
    primaryColor: '#FFFFFF',
    secondaryColor: '#98959F',
  },
  searchBar: {
    border: '1px solid #413F45',
    color: '#98959F',
    iconColor: '#7F7B87',
  },
  valueButton: {
    color: '#565B60',
    bgColor: '#FFFFFF',
    border: '#CECBD6',
    iconColor: '#794CDE',
    bgColorHover: '',
    borderHover: '',
    colorHover: '',
    iconColorHover: '',
  },

  tag: {
    color: '',
    bgColor: '',
    border: '',
  },
  dataGridItem: {
    titleColor: '#CC8593',
    iconColor: '#2A0062',
    treeLineColor: '#7F7B87',
    treeLineIconColor: '#7F7B87',
    propertyNameColor: '#7F7B87',
    propertyValueColor: '#FFFFFF',
    propertyIconColor: '#491296',
    expandIconColor: '#7F7B87',
    border: '1px solid #413F45',
    boxShadow: '2px 2px 5px 0px rgba(255, 255, 255, 0.05)',
    backgroundColor: '#252229',
    separatorLineColor: '#E9E7EE',
  },
}

export const darkTheme: Theme = {
  ...commonTheme,
  colors,
  popup: {
    wrapBgColor: 'rgba(229, 228, 233, 0.4)',
    border: undefined,
  },
  json,
  transactionDetailsPage,
}
