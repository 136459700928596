import { observer } from 'mobx-react'
import { FC } from 'react'
import {
  BasicInfoColumn,
  BasicInfoContainer,
  BasicInfoLine,
  BasicInfoSeparator,
  BasicInfoSubTree,
  BasicInfoTreeViewContainer,
  PropertyName,
  RightColRow,
  RightColumn,
} from './BasicInfo.styles'
import { BottomContent, ContainerTitle, RootLine, RootLineContainer, TopContent, TreeRow } from '../Components.styles'
import { Icon } from '../../Icons/IconMatrix'
import { formatUnixDate } from '../../methods'
import { Pill } from '../Pill'
import { Tag } from '../Tag'
import { ProviderSummary } from './ProviderSummary'
import { Provider } from '../../types'
import { ValueButton } from '../ValueButton'
import { RecursiveObjectRenderer } from '../RecursiveObjectRenderer/RecursiveObjectRenderer'

interface BasicInfoProps {
  transaction: any
  providers?: Array<Provider>
}

export const BasicInfo: FC<BasicInfoProps> = observer(({ transaction, providers }) => {
  const staticPropertyKeys = [
    'transactionId',
    'configurationId',
    'creationUnixTimeSeconds',
    'completionUnixTimeSeconds',
    'redirectUrl',
    'providers',
    'tags',
    'status',
  ]

  const dynamicPropertyKeys = Object.keys(transaction).filter((key) => !staticPropertyKeys.includes(key))

  return (
    <BasicInfoContainer>
      <TopContent>
        <Icon icon="basicInfo" />
        <ContainerTitle>
          Basic Information
          {transaction?.status && <Pill status={transaction.status} />}
          {transaction?.result && <Pill status={transaction.result} />}
        </ContainerTitle>
      </TopContent>

      <BasicInfoLine />

      <BasicInfoColumn>
        <BottomContent>
          <RootLineContainer>
            <RootLine />
          </RootLineContainer>

          <BasicInfoTreeViewContainer>
            <TreeRow>
              <PropertyName>transaction Id:</PropertyName>
              {transaction.transactionId}
            </TreeRow>
            <TreeRow>
              <PropertyName>configuration Id:</PropertyName>
              {transaction.configurationId}
            </TreeRow>
            <TreeRow>
              <PropertyName>Creation date:</PropertyName>
              {formatUnixDate(transaction.creationUnixTimeSeconds)}
            </TreeRow>
            <TreeRow>
              <PropertyName>Completion date:</PropertyName>
              {formatUnixDate(transaction.completionUnixTimeSeconds)}
            </TreeRow>
            <TreeRow>
              <PropertyName>Redirect URL:</PropertyName>
              <ValueButton action="open" actionData={transaction.redirectUrl} />
              <ValueButton action="copy" actionData={transaction.redirectUrl} />
            </TreeRow>

            <BasicInfoSubTree>
              {dynamicPropertyKeys.map((key, v) => (
                <RecursiveObjectRenderer key={v} data={transaction[key]} propertyKey={key} />
              ))}
            </BasicInfoSubTree>
          </BasicInfoTreeViewContainer>
        </BottomContent>
      </BasicInfoColumn>

      <BasicInfoSeparator />

      <RightColumn>
        <RightColRow gap={16}>
          <span>Tags:</span>
          {transaction.tags.map((tag: string, v) => (
            <Tag key={v} tag={tag} />
          ))}
        </RightColRow>

        {providers.map((provider: Provider, v: number) => (
          <ProviderSummary key={v} provider={provider} />
        ))}
      </RightColumn>
    </BasicInfoContainer>
  )
})
