import { FC } from 'react'
import { styled } from 'styled-components'

interface PillColors {
  text: string
  dot: string
  bg: string
}

const getPillColors = (status: string): PillColors => {
  const parsedStatus = status.toLowerCase()

  switch (parsedStatus) {
    case 'success':
    case 'completed':
    case 'accepted':
      return {
        text: '#2B633F',
        dot: '#2c9c77',
        bg: '#E0FCF5',
      }
    case 'failure':
    case 'rejected':
      return {
        text: '#A82626',
        dot: '#D8513F',
        bg: '#FFE9EB',
      }
    case 'expired':
      return {
        text: '#995403',
        dot: '#FB9600',
        bg: '#FFF0E2',
      }
    case 'pending':
      return {
        text: '#152F8A',
        dot: '#3D5CCA',
        bg: '#ECEFFB',
      }

    default:
      return {
        text: '#767676',
        dot: '#DFDBD6',
        bg: '#F5F4F3',
      }
  }
}

const Container = styled.div<{ $colors: PillColors }>`
  display: flex;
  padding: 0px 12px 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  height: 32px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;

  color: ${({ $colors }) => $colors.text};
  background-color: ${({ $colors }) => $colors.bg};

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ $colors }) => $colors.dot};
  }
`

interface PillProps {
  status?: string
}

export const Pill: FC<PillProps> = ({ status }) => {
  const capitalizedStatus = status?.charAt(0).toUpperCase() + status?.slice(1)

  return (
    <Container $colors={getPillColors(status)}>
      <div />
      {capitalizedStatus}
    </Container>
  )
}
