import { FC } from 'react'
import { CollapseIcon } from '../Components/Components.styles'

export const Caret: FC<{ isCollapsed?: boolean; set: (boolean) => void }> = ({ isCollapsed, set }) => (
  <CollapseIcon $isCollapsed={isCollapsed} onClick={() => set(!isCollapsed)}>
    <svg viewBox="0 0 24 24">
      <path
        d="M17.9598 9.04023C18.2136 9.29407 18.2136 9.70563 17.9598 9.95947L12.9598 14.9595C12.7059 15.2133 12.2944 15.2133 12.0405 14.9595L7.04053 9.95947C6.78669 9.70563 6.78669 9.29408 7.04053 9.04023C7.29437 8.78639 7.70593 8.78639 7.95977 9.04023L12.5001 13.5806L17.0405 9.04023C17.2944 8.78639 17.7059 8.78639 17.9598 9.04023Z"
        fill="currentColor"
      />
    </svg>
  </CollapseIcon>
)
