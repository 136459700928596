import { FC } from 'react'

export const TitleIcon: FC = () => (
  <svg width="35" height="29" viewBox="0 0 35 29">
    <g id="logo">
      <path
        id="Ellipse 55"
        d="M24.5012 15C24.5012 21.7655 19.0167 27.25 12.2512 27.25C5.48573 27.25 0.0012207 21.7655 0.0012207 15C0.0012207 8.23451 5.48573 2.75 12.2512 2.75C19.0167 2.75 24.5012 8.23451 24.5012 15Z"
        fill="#41FFD1"
      />
      <g id="Group 72805">
        <path
          id="Ellipse 53 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.0012 1.5C14.4114 1.5 11.5012 4.41015 11.5012 8C11.5012 11.5899 14.4114 14.5 18.0012 14.5C21.5911 14.5 24.5012 11.5899 24.5012 8C24.5012 4.41015 21.5911 1.5 18.0012 1.5ZM10.5012 8C10.5012 3.85786 13.8591 0.5 18.0012 0.5C22.1434 0.5 25.5012 3.85786 25.5012 8C25.5012 12.1421 22.1434 15.5 18.0012 15.5C13.8591 15.5 10.5012 12.1421 10.5012 8Z"
          fill="#2A0062"
        />
        <path
          id="Ellipse 54 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.0012 18C11.8823 18 7.00122 22.519 7.00122 28C7.00122 28.2761 6.77736 28.5 6.50122 28.5C6.22508 28.5 6.00122 28.2761 6.00122 28C6.00122 21.883 11.4176 17 18.0012 17C24.5849 17 30.0012 21.883 30.0012 28C30.0012 28.2761 29.7774 28.5 29.5012 28.5C29.2251 28.5 29.0012 28.2761 29.0012 28C29.0012 22.519 24.1201 18 18.0012 18Z"
          fill="#2A0062"
        />
        <path
          id="Vector 89 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.0012 21L33.5012 21L33.5012 22L27.0012 22L27.0012 21Z"
          fill="#2A0062"
        />
        <path
          id="Vector 99 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.5012 23.5L34.5012 23.5L34.5012 24.5L28.5012 24.5L28.5012 23.5Z"
          fill="#2A0062"
        />
        <path
          id="Vector 100 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.0012 26L34.5012 26L34.5012 27L29.0012 27L29.0012 26Z"
          fill="#2A0062"
        />
        <path
          id="Vector 90 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.0012 18.5L32.5012 18.5L32.5012 19.5L24.0012 19.5L24.0012 18.5Z"
          fill="#2A0062"
        />
        <path
          id="Vector 91 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5012 16H29.5012V17H21.5012V16Z"
          fill="#2A0062"
        />
        <path
          id="Vector 92 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5012 13.5L27.5012 13.5L27.5012 14.5L21.5012 14.5L21.5012 13.5Z"
          fill="#2A0062"
        />
        <path
          id="Vector 93 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.0012 11L28.5012 11L28.5012 12L24.0012 12L24.0012 11Z"
          fill="#2A0062"
        />
        <path
          id="Vector 94 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5012 8.5L29.5012 8.5L29.5012 9.5L24.5012 9.5L24.5012 8.5Z"
          fill="#2A0062"
        />
        <path
          id="Vector 95 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5012 6L30.0012 6L30.0012 7L24.5012 7L24.5012 6Z"
          fill="#2A0062"
        />
        <path
          id="Vector 96 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.5012 3.5L29.5012 3.5L29.5012 4.5L23.5012 4.5L23.5012 3.5Z"
          fill="#2A0062"
        />
        <path
          id="Vector 101 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.7135 0.539182C22.3598 0.267054 23.3006 0 24.5012 0C25.7019 0 26.6427 0.267054 27.289 0.539182C27.6118 0.675107 27.8611 0.812234 28.0328 0.917921C28.1187 0.970785 28.1854 1.01587 28.2323 1.04912C28.2557 1.06575 28.2743 1.07944 28.2879 1.08967C28.2946 1.09479 28.3002 1.09905 28.3045 1.10239L28.31 1.10669L28.312 1.10831L28.3129 1.10898L28.3132 1.10929C28.3134 1.10943 28.3136 1.10957 28.0012 1.5L28.3136 1.10957C28.5292 1.28207 28.5642 1.59672 28.3917 1.81235C28.2197 2.02738 27.9063 2.06274 27.6907 1.89187C27.6903 1.89159 27.69 1.89132 27.6897 1.89104C27.6895 1.89092 27.6894 1.89081 27.6892 1.89069L27.6895 1.89092L27.6897 1.89104L27.69 1.89132L27.6907 1.89186L27.6854 1.88786C27.6796 1.88345 27.6691 1.87565 27.654 1.86494C27.6237 1.84351 27.5752 1.81046 27.5087 1.76958C27.3758 1.68777 27.1719 1.57489 26.901 1.46082C26.3598 1.23295 25.5506 1 24.5012 1C23.4519 1 22.6427 1.23295 22.1015 1.46082C21.8306 1.57489 21.6267 1.68777 21.4938 1.76958C21.4273 1.81046 21.3787 1.84351 21.3485 1.86494C21.3334 1.87565 21.3229 1.88345 21.317 1.88786L21.3118 1.89186L21.3125 1.89132L21.313 1.89092L21.3133 1.89069C21.3128 1.89108 21.3123 1.89147 21.3118 1.89187C21.0962 2.06274 20.7828 2.02738 20.6108 1.81235C20.4383 1.59672 20.4733 1.28207 20.6889 1.10957L21.0012 1.5C20.6889 1.10957 20.6891 1.10943 20.6892 1.10929L20.6896 1.10898L20.6905 1.10831L20.6925 1.10669L20.698 1.10239C20.7023 1.09905 20.7078 1.09479 20.7146 1.08967C20.7282 1.07944 20.7467 1.06575 20.7702 1.04912C20.8171 1.01587 20.8838 0.970785 20.9697 0.917921C21.1414 0.812234 21.3906 0.675107 21.7135 0.539182Z"
          fill="#2A0062"
        />
      </g>
    </g>
  </svg>
)
