import { commonTheme } from './common.theme'
import { Colors, Json, Theme, TransactionDetailsPage } from './theme'

const colors: Colors = {
  primary: '#41FFD1',
  primary2: '#28e2b4',
  secondary: '#2A0062',
  secondary2: '#2A0062',
  secondary3: '#2A0062',
  secondary4: '#2A0062',
  secondary5: '#2A0062',
  secondary6: '#2A0062',
  secondary7: '#491296',
  secondary8: '#491296',
  secondary9: '#2A0062',
  tertiary: '#F2F0F6',
  tertiary2: '#F2F0F6',
  tertiary3: '#F2F0F6',
  quaternary: '#FFFFFF',
  quaternary2: '#FFFFFF',
  quaternary3: '#FFFFFF',
  quaternary4: '#FFFFFF',
  quaternary5: '#FFFFFF',
  quinary: '#E5E4E9',
  senary: '#9B91B0',
  septenary: '#98959F',
  septenary2: '#98959F',
  septenary3: '#665784',
  scrollTrackColor: '#9B91B0',
  scrollHandleColor: '#2A0062',
}

const json: Json = {
  textColor: '#2A0760',
  textSecondaryColor: '#2A0760',
  headlineColor: '#2A0760',
  backgroundColor: '#F2EDFE',
  lineHeight: '22px',
  linePadding: '0px 3px 0px 4px',
  borderDefault: 'rgba(255, 255, 255, 0.23)',
  borderFocused: '#05aeca',
  borderInvalid: '#9d190f',
  selectionMatch: '#ae38a782',
  activeLineGutter: '#7D18FF11',
  activeLine: '#7D18FF11',
  activeSelection: '#1872b9',
  dividerColor: '#EEE',
  spacing: '4px',
  contentPadding: `calc(4px / 2)`,
  borderRadius: '8px',
}

const transactionDetailsPage: TransactionDetailsPage = {
  backButton: {
    color: '#25292C',
  },
  title: {
    primaryColor: '#2B253A',
    secondaryColor: '#565B60',
  },
  searchBar: {
    border: '1px solid #B7BDC6',
    color: '#91969B',
    iconColor: '#6C7176',
  },
  valueButton: {
    color: '#565B60',
    bgColor: '#FFFFFF',
    border: '1px solid #CECBD6',
    iconColor: '#794CDE',
    bgColorHover: '',
    borderHover: '',
    colorHover: '',
    iconColorHover: '',
  },
  tag: {
    color: '#2A0062',
    bgColor: '#F7F9FC',
    border: '1px solid #E9E7EE',
  },
  dataGridItem: {
    titleColor: '#190147',
    iconColor: '#794CDE',
    treeLineColor: '#DCCDFF',
    treeLineIconColor: '#DCCDFF',
    propertyNameColor: '#7F858B',
    propertyValueColor: '#2B253A',
    propertyIconColor: '#5F36BC',
    expandIconColor: '#303438',
    border: '1px solid #E6DDF9',
    boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FFFFFF',
    separatorLineColor: '#E9E7EE',
  },
}

export const lightTheme: Theme = {
  ...commonTheme,
  colors,
  popup: {
    wrapBgColor: 'rgba(12, 1, 27, 0.59)',
    border: `2px solid ${colors.tertiary2}`,
  },
  json,
  transactionDetailsPage,
}
