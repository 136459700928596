import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { Container, Logo, Search, StyledMenu, Tenant } from './TopMenu.styles'
import { StoreContext } from '../App'
import { SignicatLogo } from './Components/SignicatLogo'
import { Notifications } from './Components/Notifications'
import { UserInfo } from './Components/UserInfo'
import { TenantSwitcher } from './Components/TenantSwitcher'

export const TopMenu: FC = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Container>
      <StyledMenu>
        <Logo>
          <SignicatLogo />
        </Logo>
        <Search> </Search>
        <Notifications />
        <UserInfo />

        <TenantSwitcher />
      </StyledMenu>
    </Container>
  )
})
